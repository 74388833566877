<template>
  <app-overlay loading-variable="modalOverlay">
    <validation-observer ref="newRoleForm">
      <app-select-input
        v-model="group"
        name="Grup"
        :options="groups"
        select_label="value"
        label="Grup:"
        placeholder="Grup Seçiniz..."
        rules="required"
        :reduce="(option) => option.key"
      />
      <app-input v-model="value" name="Rol Adı" label="Rol Adı:" placeholder="Rol Adı..." rules="required" />
      <role-permissions v-model="selectedPermissions" :group="group" />
      <app-button @click="createRole" text="Oluştur" icon="PlusIcon" size="md" block />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import RolePermissions from "./RolePermissions.vue";
import { ValidationObserver } from "vee-validate";
import { roleGroups } from "./roleUtils";
export default {
  components: { ValidationObserver, RolePermissions },
  data() {
    return {
      group: "",
      value: "",
      groups: roleGroups,
      selectedPermissions: [],
    };
  },
  computed: {
    callback() {
      return this.$store.getters?.appModalItem?.callback;
    },
  },
  methods: {
    createRole() {
      this.$validate(this.$refs.newRoleForm, () => {
        this.$axios
          .post(
            "/permissions/roles",
            {
              group: this.group,
              value: this.value,
              selectedPermissions: this.selectedPermissions,
            },
            { loading: "modal" }
          )
          .then((result) => {
            this.$emitter?.$emit("Notification", { title: "İşlem Başarılı", variant: "success" });
            this.callback && this.callback();
            this.$store.commit("cleanAppModal");
          })
          .catch((err) => {
            this.$emitter?.$emit("Notification", { title: "Hata Oluştu", variant: "danger" });
          });
      });
    },
  },
};
</script>

<style></style>
