<template>
  <app-overlay>
    <db-searchable-table :items="roles" :fields="fields" :total-rows="roles.length" @row-clicked="showRolePermissionModal" searchable hover local>
      <template #modalbutton>
        <app-button @click="showNewRoleForm" text="Rol Oluştur" size="md" icon="PlusIcon" />
      </template>
      <template #group="{ item }">
        {{ labels[item.group] ? labels[item.group] : "-" }}
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import NewRoleForm from "./NewRoleForm.vue";
import { roleGroupLabels } from "./roleUtils";
import RolePermissions from "./RolePermissions.vue";
export default {
  data() {
    return {
      roles: [],
      total: 0,
      fields: [
        { key: "value", label: "Rol" },
        { key: "group", label: "Grup" },
        { key: "actions", label: "İşlemler" },
      ],
      labels: roleGroupLabels,
    };
  },
  methods: {
    getData() {
      this.$axios
        .get("/permissions/roles", { loading: "table" })
        .then((result) => {
          this.roles = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showNewRoleForm() {
      this.$showAppModal({ title: "Yeni Rol Ekle", component: NewRoleForm, item: { callback: this.getData } });
    },
    showRolePermissionModal(item) {
      item.key !== "superAdmin" && this.$showAppModal({ title: item.value + " İzinleri Güncelle", component: RolePermissions, size: "lg", item: { data: { ...item } } });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
