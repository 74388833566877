<template>
  <app-overlay :loading-variable="!group ? 'modalOverlay' : 'loadingOverlay'">
    <div class="border rounded mb-1">
      <div class="text-center m-2">
        <h4>Erişim İzinleri</h4>
        <small> Bu role sahip kullanıcıların hangi sayfalara erişebileceğini seçin. </small>
      </div>
      <b-table :items="permissions" :fields="fields" show-empty empty-text="Bu rol grubu için mevcut izin bulunmamaktadır." style="max-height: 500px" sticky-header responsive>
        <template #cell(title)="{ item }">
          <h5>
            {{ item.title }}
          </h5>
          <small v-if="item.subTitle">{{ item.subTitle }} </small>
        </template>
        <template v-for="(item, key) in ['create', 'read', 'write', 'delete']" :slot="`cell(${item})`" slot-scope="data">
          <b-form-checkbox @change="checked(data.item, item)" :key="key" v-if="data.item && data.item[item]" :checked="getValue(data.item, item)"></b-form-checkbox>
        </template>
      </b-table>
    </div>
    <div v-if="role && permissions.length">
      <b-button variant="primary" @click="submitRole">Kaydet</b-button>
      <hr />
    </div>
  </app-overlay>
</template>

<script>
export default {
  props: {
    group: {
      type: String,
    },
  },
  data() {
    return {
      permissions: [],
      selected: [],
      fields: [
        { key: "title", label: "Sayfa" },
        { key: "read", label: "Okuma" },
        { key: "create", label: "Oluşturma" },
        { key: "write", label: "Güncelleme" },
        { key: "delete", label: "Silme" },
      ],
    };
  },
  computed: {
    role() {
      return this.$store.getters?.appModalItem?.data;
    },
  },
  methods: {
    checked(item, action) {
      const permission = { permission: item[action], role: this?.role?._id };
      const index = this.selected.findIndex((p) => p.permission === permission.permission);
      if (index !== -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(permission);
      }
      this.$emit("input", this.selected);
    },
    getValue(item, action) {
      const permission = { permission: item[action], role: this.role?._id };
      const index = this.selected.findIndex((p) => p.permission === permission.permission);
      return !!this.selected[index];
    },
    submitRole() {
      this.role &&
        this.$axios
          .post(`/permissions/role-permissions/create-many/${this.role?._id}`, { data: this.selected }, { loading: "modal" })
          .then(() => {
            this.$emitter?.$emit("Notification", { title: "İşlem Başarılı", variant: "success" });
            this.$store.commit("cleanAppModal");
          })
          .catch((err) => {
            this.$emittier.$emit("Notification", { title: "Hata Oluştu", variant: "danger" });
            console.log(err);
          });
    },
    getPermissions() {
      if (this?.role?.group || this.group)
        this.$axios
          .get("/permissions/grouped-list", { loading: "modal", params: { group: this?.role?.group ?? this.group } })
          .then((result) => {
            this.permissions = result.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getRolePermissions() {
      this?.role &&
        this.$axios
          .get("/permissions/role-permissions", { loading: "modal", params: { role: this.role?._id } })
          .then((result) => {
            this.selected = result.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
  mounted() {
    this.getRolePermissions();
    this.getPermissions();
  },
  watch: {
    group() {
      this.getPermissions();
    },
  },
};
</script>
